<template lang="html">
    <section class="new-full-img" v-if="dataContent">
        <router-link :to="{
        name: 'page-entry',
        params: {
          typeContent: this.dataContent.type,
          slug: this.dataContent.slug
        },
      }" class="content-new" title="Enlace a la noticia">

            <span v-if="typeContent == 'multimedia'" src="/img/img-test.png" :alt="dataContent.image_footer" :class="'icon-media typeMedia'+dataContent.file_type"></span>
            <img :src="dataContent.image.url" :alt="dataContent.image_footer" class="img-new" />
            <div class="info-box">
               <h2><Markdown v-if="this.dataContent.name_markdown" class="title" :source="this.dataContent.name_markdown" /></h2>

                <p class="box-dates">
                    <span class="date-text">{{ currentDateTime(dataContent.publish_date) }} </span>
                    <span class="author" v-if="dataContent.source">{{dataContent.source}}</span>

                    <template v-for="theme in dataContent.themes" :key="theme.id">
                        <span class="category-tag">{{ theme.name }}</span>
                    </template>
                    <span class="category-location" v-if="dataContent.zone">{{
                        dataContent.zone.name
                        }}</span>
                </p>


                <p class="subtitle" v-if="dataContent.header">
                    {{ dataContent.header }}
                </p>

            </div>
        </router-link>
    </section>
</template>

<script lang="js">
    import moment from 'moment'
    import Markdown from 'vue3-markdown-it';
    export default {
        name: 'new-full-img',
        props: ['dataContent',
            'typeMedia'
        ],
        components: {
            Markdown
        },
        mounted() {

        },
        data() {
            return {

            }
        },
        methods: {
            currentDateTime(date) {
                return moment(date).locale('es').format('L')
            },
        },
        computed: {
            typeContent() {
                switch (this.dataContent.type) {
                    case 'entry':
                        return 'noticia'
                    case 'opinion':
                        return 'opinion'
                    case 'interview':
                        return 'entrevista'
                    case 'coverage':
                        return 'reportaje'
                    case 'event':
                        return 'evento'
                    case 'media':
                        return 'multimedia'
                    default:
                        return 'noticia'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";

    .content-new {
        cursor: pointer;
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        height: 100%;
        /* max-height: 300px; */
        position: relative;
        border-radius: 13px;
        padding-left: 10px;

        .title,
        .title p {
            font-size: 33px;
        }

        .category {
            position: absolute;
            left: 0px;
            top: 10px;
            z-index: 2;
            background: $orange;
            font-weight: 900;
            color: white;
            font-size: 16px;
            padding: 5px 15px;
            border-radius: 13px;
        }

        .icon-media {
            width: 50px;
            height: 50px;
            display: inline-block;
            position: absolute;
            top: 10px;
            left: 20px;
            background-position: center center;
            background-size: 45px;
            background-repeat: no-repeat;
            background-color: #0069b4b0;
            border-radius: 10px;

            &.typeMedia0 {
                background-image: url("/img/icons/icon-photo.svg");
            }

            &.typeMedia1 {
                background-image: url("/img/icons/icon-video.svg");
            }
        }

        .img-new {
            width: 100%;
            border-radius: 13px;
            height: auto;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center;
            object-position: center;
            height: 350px;
        }
    }

    .info-box {
        width: 100%;
        padding: 20px 0px;

    }

    .text-cut {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;
        /* fallback */
        max-height: 32px;
        /* fallback */
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;
    }

    @media screen and (max-width:845px) {

        .content-new .title,
        .content-new .title p {
            font-size: 22px;
        }

        .subtitle {
            font-size: 17px;
        }

        .content-new .img-new {
            height: 235px;
        }

        .content-new {
            padding-left: 0;
        }

        .info-box {
            padding: 10px 0;
        }
    }
</style>