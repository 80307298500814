<template>
    <div class="audiovisuals">
        <section class="container">
            <template v-if="medias">
                <section class="container-grid">
                    <div class="grid-big">
                        <template v-for="(media, key, index) in medias" :key="key">
                            <newFullImg v-if="index == 0" :dataContent="media" :typeMedia="media.file_type">
                            </newFullImg>
                        </template>
                    </div>
                    <section class="grid-small">
                        <template v-for="(media, key, index) in medias" :key="key">
                            <newVerticalImg v-if="index == 1" :dataContent="media" :typeMedia="media.file_type">
                            </newVerticalImg>
                        </template>
                    </section>


                    <div class="grid-3">
                        <template v-for="(document, index) in Object.values(medias)" :key="index">
                            <newVerticalImg v-if="index > 1" :dataContent="document"></newVerticalImg>
                        </template>

                    </div>
                   
                </section>
                <paginator :pageActual="page" :totalItems="MediasMaxItems" @changed="changePages"  @clicked="changePages"></paginator>
            </template>
            <div v-else class="container-noResult">
                <p class="title-dest-underline">No hay resultados</p>
            </div>
        </section>
    </div>
</template>

<script>
    // @ is an alias to /src
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from "vuex";
    import store from "@/store";
    import newFullImg from "@/components/news/new-full-img.vue";
    import newVerticalImg from "@/components/news/new-featured-vertical-img.vue";
    // import newVerticalText from "@/components/news/new-featured-vertical-text.vue";
    import paginator from "@/components/paginator.vue";
    import utils from "@/utils/utils.js";
    export default {
        name: "audiovisuals",
        components: {
            newFullImg,
            newVerticalImg,
            // newVerticalText,
            paginator
        },
        props: ['filterTime'],
        methods: {
            ...mapActions({
                loadMedias: "contents/loadMedias"
            }),
            ...mapMutations({
                setPageMedias: "contents/setPageMedias"
            }),
            changePages(item) {
                this.setPageMedias(item);
                this.loadMedias({
                    page: this.page
                });
                utils.LoaderScrollToTop();
            }
        },
        computed: {
            ...mapGetters({
                getPagesMedias: "contents/getPagesMedias",
                page: "contents/getMediasViewPage",
                getMediaPhotos: "contents/getMediaPhotos",
                getMediaVideos: "contents/getMediaVideos",
                getMediaPodcast: "contents/getMediaPodcast"
            }),
            medias: function () {
                return store.getters["contents/getMedias"](this.page);
            },
            photos() {
                return this.getMediaPhotos(this.page);
            },
            videos() {
                return this.getMediaVideos(this.page);
            },
            podcasts() {
                return this.getMediaPodcast(this.page);
            },
            MediasMaxItems() {
                return this.getPagesMedias();
            }
        },
        mounted() {
            this.loadMedias({ page: this.page });
        },
        watch: {
            filterTime: function (newVal, oldVal) {
                console.log("test");
                this.loadMedias({ page: 1 });
            }
        }
    };
</script>
<style>
    .-themes {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 60px;
        flex-direction: column;
    }

    .container-themes {
        margin-top: 30px;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
</style>